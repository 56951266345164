.Login-Container {
    text-align:center;
    margin-top: 3%;
    padding: 20px;
}
.Login {
    display: inline-block;
    max-width: 450px;
    width: 100%;
    text-align: left;
    margin-right: 150px
}
.Login-Register-Buttons {
    align-items: center;
    justify-content: center;
}
.nav-item {
    width: 110px;
    padding: 3px 10px;
    text-align: center;
}
.Logo-Login img {
    width: 50px;
    height: 50px;
}
.Logo-Login {
    padding: 10px 20px;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .Logo-Login {
        padding: 10px;
    }
  }

  .form-step {
    display: none;
  }
  .form-step.active {
    display: block;
  }
  #step1 button {
    width: 100%;
  }
  #or-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    border: none;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 24px 0 0;
  }
#or-line:before, #or-line:after {
    content: "";
    border-bottom: 1px solid #d5d5d5;
    flex: 1 0 auto;
    height: 0.5em;
    margin: 0;
}
#or-line span {
    text-align: center;
    flex: 0.2 0 auto;
    margin: 0;
}
#provider {
    margin-top: 25px;
}
#forgot {
    margin-top: 15px;
    display: block;
}
#google-button {
    background: transparent;
    border: 1px solid #d5d5d5!important;
    width: 40%;
    border: 4px;
    margin-bottom: 20px;
    border-radius: 10px;
}
.toggle-btn {
    display: none; /* Hide the button by default on larger screens */
}