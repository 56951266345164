#input-with-image {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.design-image img {
    width: 30%;
}

.design-image {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}

#input-content {
    flex: 1;
}
@media (max-height: 1000px) {
    #input-content {
        flex: 2 1;
        align-items: center;
    }
}