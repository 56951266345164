.waiting-image {
    width: 50%
}
#update-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
#code-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.code-and-update {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}
.code-and-update div:first-of-type {
    overflow: scroll;
    width: 94%;
    height: 94%;
    max-height: 500px;
}
#container {
    height: calc(100vh - 71px);
}
.update-field {
    width: 100%;
    display: flex;
    justify-content: center;
}
code {
    display: block;
    width: 100%;
    max-width: 500px;
}