@media (max-width: 800px) {
    #sidebar {
      display: none;
    }
    #sidebar.active {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white; 
        z-index: 1000;
    }
    .toggle-btn {
        display: inline-block!important;
        margin-right: 10px;
    }
}
@media (min-width: 800px) and (max-width: 1200px)  {
    #sidebar {
        flex: 2;
      }
}
.toggle-btn {
    display: none; /* Hide the button by default on larger screens */
    cursor: pointer;
}
.app-sidebar p {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
    cursor: pointer;
}
.app-sidebar p:hover {
    color: #333!important;
}
.app-sidebar p svg {
    margin-right: 20px;
}
#sidebar-content h5 {
    margin-bottom: 10px;
}
.sidebar-back {
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 15px;
}
.sidebar-back span{
    margin-left: 10px;
    vertical-align: middle;
}
.app-buttons button{
    margin-right: 10px;
}
.folder {
    list-style-type: none;
    padding-left: 0px;
}
.folders {
    list-style-type: none;
    padding-left: 1rem;
    color: rgb(72, 72, 72);
}
.folder-name {
    cursor: pointer;
}
.files {
    list-style-type: none;
    padding-left: 1rem;
    color: rgb(72, 72, 72);
    cursor: pointer;
}
.files svg {
    color: rgb(4, 170, 94);
}
.folder-structure {
    margin-top: 20px;
}
.sidebar-folder-icon, .sidebar-file-icon {
    margin-right: 7px;
    vertical-align: middle;
}
#root-folder {
    display: block;
}
#root-folder-name svg:first-child {
    margin-right: 5px;
    margin-left: 0px;
}
.rotate-icon {
    transform: rotate(90deg)
}
.sidebar-right-icon {
    margin-left: 5px;
    margin-right:5px;
}
.sidebar-file-icon {
    margin-left: 9px;
}
.folder-hidden {
    display: none;
}
.folders-hidden {
    display: none;
}
.files-hidden {
    display: none;
}
.app-buttons button svg {
    margin-right: 5px;
}
#load-download {
    height: 25px;
}