* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
  justify-content: stretch;
}
.Logo-Login img {
  width: 50px;
  height: 50px;
}
.Logo-Login {
  padding: 10px 50px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: center;
  justify-content: stretch;
  flex: 1
}

#sidebar {
  width: 250px;
  padding: 20px;
  height: 100%;
  border-right: 1px solid #d5d5d5;
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
}

#logo {
  width: 272px;
  height: 92px;
  background: url('https://www.google.com/images/branding/googlelogo/2x/googlelogo_light_color_92x30dp.png') no-repeat;
}

#search-box {
  max-width: 600px;
  padding: 15px;
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  margin-right: 5px;
  width: 70%
}

#search-button {
  background-color: #10b3ab;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  cursor: pointer;
}

#search-button:hover {
  background-color: #0b7a75;
}

#buttons {
  vertical-align: middle;
}

.search-button {
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  color: #3c4043;
  font-size: 14px;
  margin: 0 4px;
  padding: 5px 12px;
  cursor: pointer;
}

.search-button:hover {
  border: 1px solid #d1d1d1;
}

#main-content {
  width: 100%;
  flex: 4;
  padding-bottom: 20px;
  height: 100%
}

#footer {
  text-align: center;
  margin-top: 30px;
  color: #555;
}

#input-content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000730; /* Blue background color */
  padding: 10px;
  border-radius: 20px;  
}

.user-info {
  display: flex;
  align-items: center;
}

.user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  color: black; /* White text color */
  font-weight: bold;
}

.sign-out-button {
  background-color: #ff5555; /* Red background color */
  color: #fff; /* White text color */
  border: none;
  padding: 0px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
}

.sign-out-button:hover {
  background-color: #ff3333; /* Lighter red background color on hover */
}
#sidebar-content {
  flex: 5;
}

.bg-primary {
  background-color: #0b7a75!important;
  border-color: #0b7a75!important;
  color: #fff!important;
}
.text-primary {
  color: #0b7a75!important;
}
.btn-outline-primary {
  border-color: #0b7a75!important;
  color: #0b7a75!important;
}
.btn-outline-primary:hover {
  border-color: #19535f!important;
  color: #fff!important;
  background-color: #19535f!important;
}
.btn-primary {
  background-color: #0b7a75!important;
  border-color: #0b7a75!important;
  color: #fff!important;
}
.btn-primary:hover {
  border-color: #19535f!important;
  background-color: #19535f!important;
}

#loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

#loading-content img {
  height: 60px;
}